<template>
  <div>
    <!-- 卡片视图区域 -->
    <el-card>
      <!-- 第一行，输入框和添加按钮 -->
      <el-row> </el-row>
      <!-- 内容主体 -->
      <el-table :data="alarmList" stripe border>
        <!-- 第一列id -->
        <el-table-column label="id" width="100" aligin="center" prop="id">
        </el-table-column>
        <!-- 第二列名称 -->
        <el-table-column
          label="报警时间"
          width="150"
          aligin="center"
          prop="alarmTime"
        >
        </el-table-column>
        <!-- 第三列备注 -->
        <el-table-column label="设备IP" aligin="center" prop="deviceIp">
        </el-table-column>
        <el-table-column
          label="设备名称"
          aligin="center"
          prop="videoSurveillanceDeviceVo.deviceName"
        >
        </el-table-column>
        <el-table-column label="事件类型" aligin="center" prop="eventType">
        </el-table-column>
        <el-table-column label="抓拍图片" aligin="center">
          <template slot-scope="scope">
            <el-popover
              trigger="hover"
              title=""
              placement="bottom"
              v-if="scope.row.alarmPicture"
            >
              <img :src="scope.row.alarmPicture" width="400" height="300" />
              <img
                slot="reference"
                :src="scope.row.alarmPicture"
                style="height: 80px;width: 80px"
              />
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNo"
        :page-sizes="[15, 30, 50, 100]"
        :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 对话框 -->
    <el-dialog
      title="查看详情"
      :visible.sync="alarmDialogVisible"
      width="800px"
    >
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 查询入参
      queryInfo: {
        IP: '',
        limit: 15,
        pageNo: 1,
        marketId: undefined
      },
      // 报警记录数组
      alarmList: [],
      // 总数
      total: 0,
      alarmDialogVisible: false
    }
  },
  created() {
    // 查询报警记录
    this.getVideoAlarm()
  },
  methods: {
    // 定义报警记录方法
    async getVideoAlarm() {
      // 获取marketId
      this.queryInfo.marketId = window.sessionStorage.getItem('currentMarketId')
      const { data: res } = await this.$http.get(
        'video//alarmEventInformation/list',
        {
          params: this.queryInfo
        }
      )
      if (res.code !== 0) {
        this.$message.error('查询报警记录失败')
      }
      // 成功后赋值
      this.alarmList = res.data.data
      this.total = res.data.total
    },
    // limit变化，调用列表接口
    handleSizeChange(newSize) {
      this.queryInfo.limit = newSize
      this.getVideoAlarm()
    },
    // 页码变化，调用接口
    handleCurrentChange(newPage) {
      this.queryInfo.pageNo = newPage
      this.getVideoAlarm()
    }
  }
}
</script>

<style lang="less" scoped>
// img {
//   width: 80px;
//   height: 80px;
// }
</style>
